import { Infopanel } from 'src/config/generated/sanitySchema'
import { trpc } from 'src/utils/trpc'

import { Statistics } from '../Statistics'

type StatisticsSectionProps = {
  cmsData: Infopanel | undefined
  skipContainer?: boolean
}

export const StatisticsSection = ({
  cmsData,
  skipContainer = false
}: StatisticsSectionProps) => {
  if (!cmsData) return null
  const { data, isLoading } = trpc.useQuery(['statistics.all'], {
    cacheTime: 0
  })

  if (isLoading || !data) {
    return null
  }

  return (
    <Statistics
      skipContainer={skipContainer}
      statisticsData={data}
      cmsData={cmsData}
    />
  )
}
