import { ButtonLink } from 'src/components/atoms/Link'
import { StatisticItem } from 'src/components/atoms/StatisticItem'
import {
  Heading2,
  Paragraph,
  ParagraphIntro
} from 'src/components/atoms/Typography'
import {
  FullWidthContainer,
  FullWidthContainerBackgroundColor
} from 'src/components/layouts/Container'

import { Infopanel } from 'src/config/generated/sanitySchema'
import { HomepageStatsType } from 'src/types/stats'
import { getSanityImageUrl } from 'src/utils/sanity'

export interface StatisticsProps {
  statisticsData: HomepageStatsType
  cmsData: Infopanel
  skipContainer: boolean
}

export const Statistics = ({
  cmsData,
  statisticsData,
  skipContainer
}: StatisticsProps) => {
  const url = cmsData.image && getSanityImageUrl(cmsData.image)
  const DynamicContainer = skipContainer ? `div` : FullWidthContainer

  return (
    <DynamicContainer
      backgroundColor={FullWidthContainerBackgroundColor.white}
      id='section-impact'
      className='lg:py-[40px]'
    >
      <div className='block lg:hidden mb-5'>
        <Heading2>{cmsData.title}</Heading2>
        <ParagraphIntro className='!text-[18px] !mb-0'>
          {cmsData.subtitle}
        </ParagraphIntro>
      </div>
      <div className='flex flex-col-reverse lg:flex-row justify-center gap-10'>
        <div className='flex flex-col lg:basis-[480px] lg:pt-[60px] gap-5'>
          <div className='hidden lg:block'>
            <Heading2>{cmsData.title}</Heading2>
            <ParagraphIntro className='!mb-0'>
              {cmsData.subtitle}
            </ParagraphIntro>
          </div>
          <Paragraph className='whitespace-pre-wrap'>{cmsData.body}</Paragraph>
          <div className='flex flex-row gap-5'>
            {cmsData.ctas?.map(cta => {
              const isExternal = !!cta.link
              return (
                <ButtonLink
                  key={cta._key}
                  variant={cta.variant}
                  color={cta.color}
                  isExternal={isExternal}
                  href={isExternal ? cta.link! : cta.route!}
                  size='large'
                >
                  {cta.title}
                </ButtonLink>
              )
            })}
          </div>
        </div>
        <div
          className='flex flex-col gap-5 lg:gap-10 lg:py-[60px] flex-1 shrink-0 basis-[200px] lg:basis-[600px]'
          style={{
            backgroundImage: url ? `url(${url})` : 'none',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'top right',
            backgroundSize: 'contain'
          }}
        >
          <StatisticItem
            label='native trees funded.'
            statistic={statisticsData?.TotalTreesFundedAndGifted?.toLocaleString()}
          />
          <StatisticItem
            label='planting projects supported.'
            statistic={statisticsData?.PlantingsSupported?.toLocaleString()}
          />
          <StatisticItem
            label='people taking action.'
            statistic={statisticsData?.TotalPlantersAndFunders?.toLocaleString()}
          />
        </div>
      </div>
    </DynamicContainer>
  )
}
